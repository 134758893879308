export default function Keyboard() {


    function myFunction(){
        console.log(navigator.virtualKeyboard);
        navigator.virtualKeyboard.overlaysContent = true;
        navigator.virtualKeyboard.hide();
        console.log(navigator.virtualKeyboard);


    }
  

    return (
       <div>
       <input class="yes" type="button" value="Click me" onClick= {myFunction} />
       <button class="yes">Click me!</button>
       <input inputmode="none" id="name" name="name" required minlength="4" maxlength="8" size="10" />

       <script>
        navigator.virtualKeyboard.hide();
       </script>

       </div> 
       


    )
}