import './App.css';

function App() {
  return (
    <div className="App" contenteditable
    virtualkeyboardpolicy="manual"
    inputmode="none" >
      <header className="App-header" >
        
        <iframe
          className="inlineFrameExample"
          title="Inline Frame Example"
          inputmode="none"
          
          src=" https://onecompiler.com/javascript ">
        </iframe>
      
        <div className = "secondInline">
          </div> 


      </header>
    </div>
  );
}

export default App;
